import { FetchError } from 'ofetch'
import { intersects, select, tryit } from 'radash'
import {
  SubmitEudonetFormError,
  submitEudonetForm,
} from '~/scripts/api/eudonet'
import { defineComponent } from '~/scripts/utils/alpine'
import { SHA256Encrypt } from '~/scripts/utils/crypto'
import { formToObject } from '~/scripts/utils/form'
import { scrollToNextSection } from '~/scripts/utils/scroll'

type EudonetFormType = 'DOCUMENTATION' | 'JPO'

interface ProgramData {
  eudonet_formation_type_id: string
  eudonet_formation_type_name: string
  eudonet_formation_id: string
  crea_formation_id: string
  crea_formation_name: string
  school_cities: string
  back_to_school: string
  back_to_school_year: string
  amount: string
}

interface EventData {
  eudonet_event_id: string
  eudonet_formations_ids: string[]
  jpo_city: string
  type?: string
}

export interface EudonetFormOptions {
  redirectionUrl: string
  desiredProgram?: string
  program?: string
  event?: string
}

export default defineComponent((options: EudonetFormOptions) => ({
  // Eudonet formation type
  desiredProgram: '',
  // Eudonet formation ID
  program: '',
  // Eudonet event ID
  event: '',
  submitLoading: false,
  get type() {
    return this.$root.dataset.type as EudonetFormType
  },
  // Data of programs
  get programs() {
    return JSON.parse(this.$root.dataset.programs || '{}') as ProgramData[]
  },
  // Data of events
  get events() {
    return JSON.parse(this.$root.dataset.events || '[]') as EventData[]
  },
  // Available programs based on the selected desired program
  get programOptions(): { value: string; label: string }[] {
    return select(
      this.programs,
      (program) => ({
        value: program.eudonet_formation_id,
        label: program.crea_formation_name,
      }),
      (program) => {
        if (this.desiredProgram === '') return true
        return program.eudonet_formation_type_id === this.desiredProgram
      },
    )
  },
  // Available events based on the selected program or desired program
  get eventOptions(): string[] {
    const programs = this.programOptions.map((program) => program.value)
    return select(
      this.events,
      (event) => event.eudonet_event_id,
      (event) => {
        if (this.program) {
          return event.eudonet_formations_ids.includes(this.program)
        }
        return intersects(event.eudonet_formations_ids, programs)
      },
    )
  },
  // Bound to the hidden input[name="crea_formation_id"]
  get creaFormationId(): string {
    if (!this.program) return ''
    const program = this.programs.find(
      (program) => program.eudonet_formation_id === this.program,
    )
    return program?.crea_formation_id ?? ''
  },
  async init() {
    if (!options.redirectionUrl) {
      throw new Error('Missing redirection url')
    }

    if (options.program) {
      await this.$nextTick()
      // Once the list of program options is rendered, set the program
      this.program = options.program
    }
  },
  onDesiredProgramInput() {
    this.program = ''
    this.selectFirstEvent()
  },
  onProgramInput() {
    this.selectFirstEvent()
  },
  scrollToNextSection,
  async selectFirstEvent() {
    for (const radio of this.$root.querySelectorAll(
      '[name="event"][checked]',
    )) {
      radio.removeAttribute('checked')
    }

    await this.$nextTick()
    this.event = this.eventOptions[0]
  },
  async onSubmit(event: Event) {
    try {
      this.submitLoading = true
      const form = event.target as HTMLFormElement
      const data = formToObject(form)
      await submitEudonetForm(data)
      this.sendAnalyticsEvents(data)
      let query = ''
      if (this.creaFormationId) {
        query = `crea_formation_id=${this.creaFormationId}`
      }
      window.location.href = options.redirectionUrl + (query ? `?${query}` : '')
    } catch (error_) {
      const error = error_ as FetchError<SubmitEudonetFormError>

      // Display the error messages from the server
      if (error.data) {
        const errors: string[] = []
        if (error.data.data) {
          for (const fieldErrors of Object.values(error.data.data)) {
            errors.push(...Object.values(fieldErrors))
          }
        }
        const message =
          errors.length > 1
            ? `<ul class="list-disc pl-4"><li>${errors.join('</li><li>')}</li></ul>`
            : errors[0]

        this.$toast.error({ message, error })
      }
      // Display a generic error message
      else {
        this.$toast.error({
          message: this.$i18n.t('error'),
          error,
        })
      }
    } finally {
      this.submitLoading = false
    }
  },
  async sendAnalyticsEvents(data: Record<string, string | string[]>) {
    // Find the selected program
    const program = this.programs.find(
      (program) => program.eudonet_formation_id === this.program,
    )

    // Find the selected event (undefined if the form is not a JPO)
    const event = this.events.find(
      (event) => event.eudonet_event_id === this.event,
    )

    // If the event has a type use it as the form category, otherwise, use the form type
    const formCategory =
      this.type === 'JPO' ? event?.type ?? this.type : this.type

    const source = window.sessionStorage.getItem('utm_source')

    window.dataLayer?.push({
      event: 'conversion',
      reference: Date.now().toString(),
      mail: data.email,
      path: window.location.pathname,
      formCategory,
      program: program?.eudonet_formation_type_name,
      programDetails: program?.crea_formation_name,
      campus: 'CREA',
      schoolCity: program?.school_cities,
      jpoCity: event?.jpo_city,
      backToSchool: program?.back_to_school,
      backToSchoolYear: program?.back_to_school_year,
      amount: program?.amount,
      source,
    })

    const [_error, uid] = await tryit(SHA256Encrypt)(data.email as string)

    window.eaPush?.({
      uid,
      ref: Date.now(),
      estimate: '1',
      type: [formCategory, program?.eudonet_formation_type_name]
        .join(' ')
        .trim(),
      path: window.location.pathname,
      pagegroup: 'FORMULAIRE',
      'ville-ecole': program?.school_cities,
      'ville-jpo': event?.jpo_city,
      campus: 'CREA',
      'annee-entree': program?.back_to_school_year,
      'date-entree': program?.back_to_school,
      'choix-programme': program?.eudonet_formation_type_name,
      source,
    })
  },
}))
