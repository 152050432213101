import Cookies from 'universal-cookie'
import { defineComponent } from '~/scripts/utils/alpine'

const cookies = new Cookies()

interface ModalOptions {
  delay?: number /** Show the modal after delay in seconds */
  /**
   * Once shown, can be shown again after life in seconds,
   * 0 = show every time (default),
   * -1 = never show again,
   * 60*60*24*30 = show again in 30 days,
   * Ignored if delay is not set
   */
  life?: number
}

/**
 * Modal component
 * @event open-modal - To open the modal
 * @example Will automatically open the modal after 2 seconds and will not show again for 1 minute
    <div id="modal-test" x-data="Modal({ delay: 2, life: 60 })" x-bind="root">
      <p>Modal test</p>
    </div>
    <button @click="$dispatch('open-modal', { id: 'modal-test' })">Open test modal</button>
 */
export default defineComponent((options?: ModalOptions) => ({
  visible: false,
  get id() {
    return this.$root.id
  },
  get cookieName() {
    return `view-modal-${this.id.replace('modal-', '')}`
  },
  init() {
    if (!this.id) {
      console.error('Modal id is required', this.$root)
      return
    }

    this.$watch('visible', (value) => {
      this.$store.ui[value ? 'lockScrollBy' : 'unlockScrollBy'](
        `modal-${this.id}`,
      )
    })

    const { delay, life = 0 } = options ?? {}

    // Check if the modal has a delay and has not been viewed before
    if (
      delay !== undefined &&
      (life === 0 || cookies.get(this.cookieName) !== 1)
    ) {
      setTimeout(() => {
        this.visible = true

        if (life > 0) {
          // Set cookie to prevent modal from showing again
          cookies.set(this.cookieName, 1, {
            path: '/',
            expires: new Date(Date.now() + 1000 * life),
          })
        }
      }, delay * 1000)
    }
  },
  close() {
    this.visible = false
  },
  root: {
    'x-trap': 'visible',
    '@open-modal.window'({ detail }: CustomEvent<{ id: string }>) {
      console.log('open-modal', detail)
      if (this.id === detail.id) {
        this.visible = true
      }
    },
  },
}))
